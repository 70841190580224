import "../style/about.css";
import imgMotivation from "../images/motivatione.png";
import imgDuo2 from "../images/duo3.jpg";
import imgTexte from "../images/texte.webp";

function About() {
  return (
    <div className="container about">
      <h2>A Propos</h2>
      <img src={imgMotivation} alt="couché de soleil" />
      <div className="separation"></div>
      <h3>La rencontre avec un initié et des chamanes.</h3>
      <p>
        Période de l' année 2020 :<br />
        Rencontre avec un initié. Au fur et à mesure des mois et années,
        jusqu'en Avril 2023, cette personne m'a transmis, lors d'initiations,
        des connaissances magiques et ésotériques.
        <br />
        <br />
        J'ai pu étudier certaines lectures et recevoir des enseignements en
        rapport avec l'hermétisme, l'ésotérisme et la gnose.
        <br />
        Je te salue pour ta patience et ton intégrité,
        <br />
        Merci J.B.
        <br />
        <br />
        Par la suite, pendant ces trois années mon cheminement m'a amené à
        rencontrer des praticiens chamaniques et des chamanes.
        <br />
        <br />
        Ils ont à leur tour transmis leurs propres savoirs chamaniques et
        expériences. La partie visible de l'iceberg opère un retournement pour
        devenir la partie immergée. L'intérieur de l'intérieur...
        <br />
        <br />
        J'ai pu par la suite, recevoir les enseignements des esprits et des
        mondes chamaniques en incluant une pratique personnelle et très
        régulière (intensive).
        <br />
        <br />
        (Attention toutefois quand je parle des mondes chamaniques ici il est
        fait référence aux pratiques de la FSS - fondation for shamanics studies
        qui n'a rien avoir avec un chamanisme (animistes) traditionnel - qui
        vient d'une tradition - (par exemple : tradition Mongol Darkhad, ou
        lakota et bien d'autres).
        <br />
        <br />
        <h3>Le chamanisme Mongol requiert beaucoup de patience.</h3> <br />
        <br />
        La nature est une grande enseignante,
        <br />
        elle offre beaucoup à celle ou celui qui honorera la dimension sacrée.
        <br />
        <br />
        Depuis fin 2020 le chamanisme s'est beaucoup expansé en pratiquant.
        <br />
        Aujourd'hui, c'est cette voie, qui est une évidence dans le cœur, que
        j'ai décidé d'emprunter.
        <br />
        <br />
        Les ongods (esprits ancêtres du chaman comme les Mongols les nomment)
        étaient bien présents et ont commencé à préparer ma venue en Mongolie.
        <br />
        <br />
        Fin d'année 2023,
        <br />
        j'ai été contacté personnellement par Enkhtuya chamane de Mongolie,
        tradition darkhad.
        <br />
        En 2024 je décide de partir seul en Mongolie 30 jours, l'appel des
        esprits était extrêmement fort.
        <br />
        <br />
        Une part d'héritage transmis et rendu et je compris enfin pourquoi tout
        ce vécu précédent à errer sans but précis dans ce monde.
        <br />
        <br />
        Le chemin continue et s'ouvre en grand de façon verticale. L'année 2024
        promet une belle expansion d'apprentissage et de belles rencontres.
        <br />
        <br />
        Avant de partir en Mongolie je pratiquais les méthodes dites chamaniques
        de Mickael Harner - FSS - fondation for shamanics studies comme cité un
        peu plus haut, d'un point de vu purement personnel, cela ne me convient
        plus.
        <br />
        <br />
        C'était un passage obligé pour en tirer un enseignement.
        <br />
        <br />
        Le chamanisme traditionnel, (l'animisme) que ce soit les chamanes
        Mongols, les hommes et femmes médecines, le chamanisme avec les plantes
        en Amérique latine avec les Shipibos et d'autres peuples gardiens de ces
        traditions et sagesses ancestrales, tire sa force des racines des grands
        arbres majestueux, de la terre et du ciel où chaque homme devrait
        s'abreuver depuis les racines et les hauteurs des hautes montagnes.
        <br />
        <br />
        Qu'Il sème le blé dans son propre champ, afin que la culture soit
        robuste et pousse en direction du soleil.
        <br />
        <br />
        Ainsi l'homme se délectera de la douceur du nectar lumineux que la
        création lui donne abondamment en faisant preuve d'humilité, de grande
        sagesse et de réciprocité d'amour envers tout se qui existe.
        <br />
        <br />
        Le chamanisme traditionnel foisonne énormément au niveau de la
        transmission,
        <br />
        le chaman reçoit des ongods (esprits ancêtres) et donne des bénédictions
        et des soins spirituels afin de redresser et réparer l'âme de ceux dans
        le besoin.
        <br />
        <br />
        Aujourd'hui je serais heureux de vous recevoir en consultation, afin de
        vous proposer mes services chamaniques dans la tradition Mongol telle
        qu'elle m'a été transmise.
        <br />
        <br />
        <h3>Pourquoi le chamanisme, la transmission, la fabrication de tambours et
        les soins ?</h3><br />
        <br />
        Des reconnexions avec de la pratique qui ont amené un équilibre et qui
        aujourd’hui font partie intégrante de cette vie riche en enseignements.
        <br />
        <br />
        Par la suite cela amène à transmettre, pour les personnes qui souhaitent
        évoluer et qui contribueront à l’élévation avec le coeur et la lumière.
        <br />
        <br />
        Pour la partie soins chamaniques, la force vibratoire qui sera déployée
        pendant les séances de soins et autres, vous permettra d’aller plus loin
        vers des déblocages et un mieux être.
        <br />
        <br />
        Pour les tambours cela se fait uniquement sur demande pour la
        réalisation.
        <br />
        <br />
        Différentes tailles sont disponibles du 35, 40. 45, jusqu'au 50 cm en
        diamètre.
        <br />
        Selon votre demande je peux vous conseiller.
      </p>

      <img src={imgDuo2} alt="duo" />
      <div className="separation"></div>
      
      <img src={imgTexte} alt="couché de soleil" />
      <p>Merci et bonne visite</p>
    </div>
  );
}

export default About;
