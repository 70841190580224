import imgPaysage7 from "../images/paysage7.webp";
import imgRose from "../images/rose.webp";

function Nettoyage() {
  return (
    <div className="container">
      <h2>Lieu de vie, nettoyage et harmonisation</h2>
      <img src={imgPaysage7} alt="cabane au bord du lac" />
      <h3>
        Vous n'êtes pas à l'aise dans votre lieu de vie, vous ressentez de
        l'oppression etc. Un travail énergétique de nettoyage et de reconnexion
        peut vous être proposé.
      </h3>
      <div className="separation"></div>
      <p>
        Cela consiste à travailler sur la vibration actuelle du lieu. Faire
        monter ce qui doit être (ancienne énergie, mémoires, esprits
        indésirables etc).
      </p>
      <h3>Un nettoyage de printemps</h3>
      <p>
        Nous venons nettoyer, purifier et harmoniser avec le travail chamanique
        et l'aide des esprits bienveillants . Une fois cela fait, un autre
        travail commence. C'est de faire monter vibratoirement le lieu (de le
        consacrer) de réactiver des points sur le terrain si cela est nécessaire
        et de venir adapter et stabiliser l'énergie correspondante pour les
        personnes et l'habitat. Il est également possible de fabriquer un totem
        pour la porte d'entrée pour une protection supplémentaire de
        l'habitation.
        <br />
        <br />
        Totem unique fait sur mesure, pour convenir et s'adapter au lieu et au
        propriétaire(s).
      </p>
      <div className="separation"></div>
      <h3>
        Crée un lieu de guérison, d'harmonie et de ressourcement sur le long
        terme pour vous c'est possible.
      </h3>
      <img src={imgRose} alt="arbres dans foret" />
      <div className="separation"></div>
      <h2>Contact par mail ou téléphone. Merci.</h2><br/>
      <div className="separation"></div>
    </div>
  );
}

export default Nettoyage;
