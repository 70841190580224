import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Accueil from "./components/Accueil";
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import About from "./components/About";
import Instrument from "./components/Instrument";
import Cartomancie from "./components/Cartomancie";
// import SejourFab from "./components/SejourFab";
// import SeanceIndiv from "./components/SeanceIndiv";
// import SeanceMassage from "./components/SeanceMassage";
import Contact from "./components/Contact";
import Nettoyage from "./components/Nettoyage";
import BackToTopButton from "./components/BackToTopButton";
import Cgdv from "./components/Cgdv";
// import Stages from "./components/Stages";
import Ceremonies from "./components/Ceremonies";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route path="/" element={<Accueil />} />
          <Route path="/about" element={<About />} />
          <Route
            path="/tambours_chamanique_et_hochets"
            element={<Instrument />}
          />
          {/* <Route
            path="/sejour_fabrication_tambour_chamanique"
            element={<SejourFab />}
          /> */}
          {/* <Route path="/soins_chamaniques" element={<SeanceIndiv />} /> */}
          {/* <Route
            path="/journee_et_sejours_de_fabrication"
            element={<Stages />}
          /> */}
          {/* <Route path="/massage_avec_magnetisme" element={<SeanceMassage />} /> */}
          <Route
            path="/nettoyage_et_harmonisation_lieu_de_vie"
            element={<Nettoyage />}
          />
          <Route
            path="/ceremonies_soins_chamaniques"
            element={<Ceremonies />}
          />
          <Route path="/cartomancie_divination" element={<Cartomancie />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/conditions-generales" element={<Cgdv />} />
          <Route path="/a-propos" element={<About />} />
        </Routes>
        <BackToTopButton />
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
