import "../style/ceremonie.css";
import imageDuo from "../images/duo.jpg";

function Ceremonies() {
  return (
    <div className="container">
      <h3>Cérémonies, soins chamaniques traditionnel </h3>
      <p>
        Que peut vous apporter l'apport d'un soin Chamanique dans la vie
        quotidienne.
      </p>
      <br />
      <p>
        Vous amener plus de clarté sur une ou plusieurs situations. Mettre en
        lumière ce qui est prêt à émerger et par la suite en prendre conscience.
        Gérer au mieux une situation problématique, afin de se sentir alléger,
        pour mieux y faire face et pouvoir avancer avec bienveillance et
        sérénité. Travailler sur une addiction (tabac, alcool, drogue,
        nourriture, etc etc ). Bénéficiez d'une protection sur différentes
        situations de vie. Venir compléter la médecine actuelle, pour les
        personnes en traitement de chimiothérapie et autres maladies. Opérer sur
        des mémoires ancestrales qui vous bloquent et qui ont besoin d'être
        libérées (ordre karmique, karma ancestral, libération de lignées) etc
        etc. Bénéficiez de plus d'ancrage au quotidien et se sentir beaucoup
        plus dans son corps en présence et en sécurité. Les soins s'adaptent
        toujours aux personnes pour qu'elles puissent recevoir ce dont il faut
        pendant la séance.
      </p>
      <br />
      <p>
        Les esprits travaillent en passant par le corps du chaman, il devient un
        réceptacle des forces qui l'habitent (les esprits, les ancêtres).
      </p>
      <div className="cardPrice">
        Soins chamaniques (individuel)
        <br /> En présentiel ou bien à distance.
        <br />
        1ère Séance 1h : 90 € Séance de suivie : 80 €
        <br />
        Offrande pour le chaman : 1 bouteille de lait entier.
      </div>
      <div className="cardPrice">
        Soins chamaniques pour enfant de 1 jusqu'à 12 ans
        <br />
        Séance 30 min : 40 €
        <br />
        Présence d'un parent obligatoire.
      </div>
      <br />
      <p>
        <h3>Association Mongol :</h3><br />
        (Sur chaque séance de soins chamaniques et cérémonies 5€ sont reversés à
        l'association de mon enseignante en Mongolie.
        <br />
        Cette association à pour but d'aider les personnes en difficultés. Femme
        isolée avec enfants, personne ayant des problèmes avec l'alcool, payer
        certains frais d'hospitalisation pour les soins, acheter des vêtements chauds au plus
        démunis, nourriture etc, ectc...)tout est suivi et tracé par mon
        enseignante)
      </p>
      <div className="separation"></div>
      <h4>Déroulement de la séance :</h4>
      <p>
        Prise de connaissance de la problématique, travail sur la problématique,
        nettoyage, rééquilibrage, harmonisation. Le travail s'effectue à la
        guimbarde, avec le déploiement du son, cela me permet de rentrer en état
        modifié de conscience et ainsi pouvoir communiquer avec les ongods
        (esprits) du chaman. Ensuite ils peuvent commencer à travailler sur la
        personne et lui apporter ce dont il faut sur le moment. Cela peut être
        des bénédictions, du nettoyage, rééquilibrage et harmonisation de vos
        corps. Une information que le défunt aimerait transmettre ou bien faire
        monter une âme qui est accrochée à vous.
      </p>
      <div className="separation"></div>
      <h4>Cérémonies individuelles ou collectives :</h4>
      <p>
        Offrandes Pour le chaman : Lait.
        <br /> Si vous souhaitez vous pouvez amener d'autres offrandes en plus
        du lait. Thé, Vodkas, cigarettes, Bonbons, gâteaux.
      </p>
      <br />
      <p>
        Pour les cérémonies la présence du tushee (assistant du chaman) est
        indispensable. Ce sont ses yeux dans la matière et plus encore, la
        collaboration est très importante et sacrée entre les deux individus.
        Pendant la cérémonie le tushee accompagne, parle, offre du lait, des
        cigarettes ou de la vodka pour les (ongods) du chaman et s'occupe des
        personnes qui souhaiteraient passé et se présenter devant les ongods.
        <br />
        <br />
        <div className="donnation">
          <p>
            Cérémonies individuelles:
            <br />
            120 €
          </p>
          <br />
          <p>
            Cérémonies collectives:
            <br />
            80 € / pers
          </p>
          <br />
          <p>
            Si vous souhaitez faire une cérémonie chez vous à votre domicile
            nous pouvons nous déplacer. Frais supplémentaires inclus plour
            déplacement, à voir tout ceci avec vous.
          </p>
          <div className="separation"></div>
          <p>
            Pour une demande de prise de rendez-vous. Vous pouvez me joindre par
            mail ou bien par téléphone. Merci.
          </p>
        </div>
      </p>
      <div className="separation"></div>
      <h4>Benjamin Lafforgue chaman initié et élève d'Enkhtuya.</h4>
      <img src={imageDuo} alt="chaman" />
    </div>
  );
}

export default Ceremonies;
