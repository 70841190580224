import "../style/ceremonie.css";

import imgPaysage from "../images/paysage2.webp";
import imgHochet from "../images/hochet.webp";
import imgTambour20 from "../images/tambour20.webp";
import imgTambour21 from "../images/tambour21.jpeg";
import imgTambour8 from "../images/imgTamb.webp";
import imgTambour9 from "../images/tambourImg9.webp";
import tambourAigle1 from "../images/tambourAigle1.jpg";
import tambourAigle2 from "../images/tambourAigle2.jpg";
import tambourAigle3 from "../images/tambourAigle3.jpg";
import tambourAigle4 from "../images/tambourAigle4.jpg";
// import tambourAigle5 from "../images/tambourAigle5.jpg";

function Instrument() {
  return (
    <div className="container">
      <h2>Tambours chamaniques et hochets</h2>
      <div className="separation"></div>
      <div className="donnation">
        {" "}
        <p>
          Je peux réaliser votre tambour sur demande, vous aurez ainsi un allié,
          un enseignant qui vous accompagnera lors de vos pratiques.
        </p>
      </div>

      <img src={imgPaysage} alt="paysage de montagne" id="img2" />
      <div className="separation"></div>
      <p className="citation">
        "La nature fait les choses sans se presser et pourtant tout est
        accompli." <br />
        Lao Tseu.
      </p>
      <div className="separation"></div>
      <h3>Hochet chamanique</h3>
      <h3>A partir de 60€</h3>
      <img src={imgHochet} alt="tambour chamanique" />
      <div className="separation"></div>
      <h3>
        Si vous voulez venir essayer un tambour pour le ressentir c'est
        possible. Il suffit juste de me joindre par téléphone. Ainsi nous
        trouverons ensemble un créneau. Merci.
      </h3>
      <div className="separation"></div>
      <h3>Présentation de quelques tambours réalisés.</h3>
      <div className="separation"></div>
      <h3>Tambour bouc en 40cm</h3>
      <img src={imgTambour8} alt="tambour chamanique" id="img12" />
      <p className="description">
        Tambour 40cm en peau de bouc. Cadre en frêne 7,5cm de profondeur.
        Tressage tout autour du tambour avec laces de bouc. Mayoche en chêne et
        cuir de chèvre. Tambour convenable pour le poids.
      </p>
      <img src={imgTambour9} alt="tambour chamanique" id="img13" />
      <p>Poignée en cuir de chèvre.</p>
      <div className="separation"></div>
      <h3>Tambour bison 45cm avec direction</h3>
      <img src={imgTambour20} alt="tambour chamanique" id="img12" />
      <img src={imgTambour21} alt="tambour chamanique" id="img12" />
      <p className="description">
        Cadre en hêtre. Direction en cuir. Mayoche en chêne et cuir.
      </p>
      <div className="separation"></div>
      
      <h3>Esprit de l'aigle</h3>
      <img src={tambourAigle1} alt="tambour chamanique" id="img12" />
      <img src={tambourAigle2} alt="tambour chamanique" id="img12" />
      <img src={tambourAigle3} alt="tambour chamanique" id="img12" />
      <p>
        Tambour 45 cm en peau de biche, lasses en cerf, poignée en cuir de
        chèvre, cadre en hêtre, mayoche en bouleau et cuir. Ancien tambour que
        je transmets.{" "}
      </p>
      <div className="separation"></div>
      <h3>Esprit de l'aigle, oiseaux tonnerre</h3>
      <img src={tambourAigle4} alt="tambour chamanique" id="img12" />
      {/* <img src={tambourAigle5} alt="tambour chamanique" id="img12" /> */}
      <p>
        Tambour 65 cm en peau de cerf, poignée en chêne, cuir avec direction,
        mayoche en noisetier et cuir de cerf. Cadre en Chêne, pierre obsidienne
        oeil céleste incrusté dans la poignée. Ancien tambour que je transmets.
      </p>
    </div>
  );
}

export default Instrument;
