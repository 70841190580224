import "../style/ceremonie.css";
import imgChouette from "../images/chouette.jpg";
import imgCartes1 from "../images/cartes1.jpg";
import imgCartes2 from "../images/cartes2.jpg";

function Cartomancie() {
  return (
    <div className="container">
      <h3>Cartomancie, Oracles, Divination, Tirages</h3>
      <p>
        Je vous propose des séances de divination sur support Oracles sur les 4
        domaines ci dessous. Prise de rendez-vous par téléphone. Séance en
        présentiel ou bien à distance en visio.
      </p>
      <div className="separation"></div>
      <h4>
        Tirages domaines :
        <br />
        professionnel, sentimentale, argent, santé.
      </h4>
      <div className="cardPrice">
        Tirage 1 domaine :
        <br />
        20 € /15 minutes
        <br />
        Tirage 2 domaines :
        <br />
        40 € / 30 minutes
        <br />
        {/* Tirage 3 domaines :<br />
        45 € / 40 minutes.
        <br /> */}
        Tirage général 4 domaines :
        <br />
        60 € / 50 - 1 heure environ.
      </div>
      <img src={imgChouette} alt="une chouette" />
      <div className="containerCarte">
        <div className="imgCartes">
          <div className="imgCarte">
            <img src={imgCartes1} alt="carte de jeux" />
          </div>
          <div className="imgCarte">
            <img src={imgCartes2} alt="carte de jeux" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cartomancie;
