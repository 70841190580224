import "../style/menuSlide.css";

function MenuSlide() {
  return (
    <ul className="menuSlide">
      <li className="menuSlide-link" id="liTop">
        <a href="/about">A Propos</a>
      </li>
      <li className="menuSlide-link">
        <a href="/tambours_chamanique_et_hochets">
          Tambours et hochets chamaniques
        </a>
      </li>
      <li className="menuSlide-link">
        <a href="/ceremonies_soins_chamaniques">
          Cérémonies, soins chamaniques
        </a>
      </li>
      <li className="menuSlide-link">
        <a href="/cartomancie_divination">
          Cartomancie, divination</a>
      </li>

      {/* <li className="menuSlide-link">
        <a href="/journee_et_sejours_de_fabrication">
          Journée et séjours de fabrication
        </a>
      </li> */}
      {/* <li className="menuSlide-link">
        <a href="/soins_chamaniques">Soins chamaniques</a>
      </li> */}

      <li className="menuSlide-link">
        <a href="/nettoyage_et_harmonisation_lieu_de_vie">
          Lieu de vie, nettoyage et harmonisation
        </a>
      </li>
      <li className="menuSlide-link" id="liBottom">
        <a href="/contact">Contact</a>
      </li>
    </ul>
  );
}

export default MenuSlide;
