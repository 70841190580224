import "../style/accueil.css";
// import image from "../images/logonb3.png";
import image1 from "../images/aigleAccueil.jpg";

function Accueil() {
  return (
    <div className="container">
      <img src={image1} alt="dessin bison" className="imgLogoNb" />
      <h2>Bienvenue sur le site Elévation Chamanique</h2>

      <h4 className="textAcceuil">
        <h4>
          Pratique chamanique ancestrale et traditionnelle.
          <br />
          - Cérémonies, groupes
          <br />
          - Soins chamaniques
          <br />
          - Tambours chamaniques sur demande
          <br/>
          Disponibilité du lundi au vendredi 10h/20h et le samedi 10h/17h.
          <br />
          Sur rendez-vous uniquement.
          <br />
          
          {/* <p className="dispo">Indisponible du 01/06 au 03/08.</p> */}
          
          Bonne visite.
          <br />
          <br />
          Benjamin
        </h4>
      </h4>
      <div className="accueil-info"></div>
    </div>
  );
}

export default Accueil;
